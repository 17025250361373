import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { formatNumber } from "@angular/common";

@Pipe({
  standalone: true,
  name: "mtd24Currency",
})
export class Mtd24CurrencyPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private _locale: string, @Inject(DEFAULT_CURRENCY_CODE) private _defaultCurrencyCode: string = "USD") {}

  transform(
    value: number | null | undefined,
    currencyCode: string = this._defaultCurrencyCode,
    display: "code" | "symbol" | "symbol-narrow" | string | boolean = "symbol",
    digitsInfo?: string,
    locale?: string
  ): string | null {
    if (!isValue(value)) return null;

    return `${formatNumber(value, "de-de", "1.0-0").replace("-", "– ")},- €`;
  }
}

function isValue(value: number | string | null | undefined): value is number | string {
  return !(value == null || value === "" || value !== value);
}
