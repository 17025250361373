import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PromotionsDto } from 'src/app/model/Promotion';
import { NgClass, DatePipe } from '@angular/common';

@Component({
    selector: 'itcw-component-page-header',
    templateUrl: './component-page-header.component.html',
    styleUrls: ['./component-page-header.component.scss'],
    host: { 'class': 'd-block mb-4' },
    standalone: true,
    imports: [NgClass, DatePipe]
})
export class ComponentPageHeaderComponent implements OnInit {

  constructor() { }

  @Input() headerStyleSize: string = "h1";
  @Input() promotions: PromotionsDto[] = []


  ngOnInit() {
  }
}
