import { DownloadNode } from "../shared/products/product-downloads/product-downloads-component";

export interface ProductCategoryConfig {
  ProductGroupKey: ProductGroupKey;
  ProductGroupTitle: string;
  Title: string;
  ShortTitle: string;
  WidthMin?: number;
  WidthMax?: number;
  DepthMin?: number;
  DepthMax?: number;
  AllowCustomSize: boolean;
  NoOfPolesFixed: boolean;
  InstallationHeightEnabled: boolean;
  ImageSrc: string;
  ImageDesc: string;
  ShowGutter: boolean;
  GigaGutterAddition?: number | null;
  StandardGutterAddition: number | null;
  RoofPitchDesc: string;
  RoofsDesc: string;
  ColorsDesc: string;
  SketchDimensionsImgUrl?: string;
  Downloads?: DownloadNode[];
}

export enum ProductGroupKey {
  KompaktXXS = "tudk",
  EinsteigerXS = "tude",
  PremiumXL = "tud",
  PremiumXLFS = "tudpfs",
  SuperiorXXL = "tudsp",

  //Freistehend = "tudf",
  Schiebedach = "tuds",
  SchiebedachFS = "tudsfs",

  FlachdachXLWandanschluss = "tudfdw",
  FlachdachXLEck = "tudfde",
  FlachdachXLNische = "tudfdn",
  FlachdachXLFreistehend = "tudfdf",
  FlachdachXXL = "tudfdxxl",
  Lamellendach = "tudl",
  Gartenzimmer = "tudg",
  Lagerverkauf = "tudlv",
  Gartenhaus = "gha",
}

export const ProductCategoryConfigs: Map<string, ProductCategoryConfig> = new Map([
  [
    ProductGroupKey.KompaktXXS, ///////
    {
      ProductGroupKey: ProductGroupKey.KompaktXXS,
      ProductGroupTitle: "Terrassendach",
      Title: "Terrassenüberdachung Kompakt XXS Wandanschluss",
      ShortTitle: "Kompakt XXS Wandanschluss",
      AllowCustomSize: false,
      NoOfPolesFixed: false,
      InstallationHeightEnabled: false,
      ImageSrc: "produkte/wandanschluss/kompakt-xxs/detailseite-banner-kompakt-xxs.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: false,
      GigaGutterAddition: null,
      StandardGutterAddition: null,
      RoofPitchDesc: "Standard 8° (keine andere Dachneigung möglich)",
      RoofsDesc: "",
      ColorsDesc: "",
      SketchDimensionsImgUrl: "teasers/masse-breite-tiefe-beispiel.png",
      Downloads: [
        {
          name: "Montageanleitung Terrassendach ",
          children: [
            {
              name: "Montageanleitung Kompakt XXS Compact Line 304x250 cm",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/6_Aktionsmodell/Montageanleitung_Terrassendach/Montageanleitung_Kompakt_XXS_Compact_Line_304x250_cm.pdf",
            },
            {
              name: "Montageanleitung Kompakt XXS Compact Line 304x300 cm",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/6_Aktionsmodell/Montageanleitung_Terrassendach/Montageanleitung_Kompakt_XXS_Compact_Line_304x300_cm.pdf",
            },
            {
              name: "Montageanleitung Kompakt XXS Compact Line 424x250 cm",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/6_Aktionsmodell/Montageanleitung_Terrassendach/Montageanleitung_Kompakt_XXS_Compact_Line_424x250_cm.pdf",
            },
            {
              name: "Montageanleitung Kompakt XXS Compact Line 424x300 cm",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/6_Aktionsmodell/Montageanleitung_Terrassendach/Montageanleitung_Kompakt_XXS_Compact_Line_424x300_cm.pdf",
            },
            {
              name: "Montageanleitung Kompakt XXS Compact Line 544x250 cm",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/6_Aktionsmodell/Montageanleitung_Terrassendach/Montageanleitung_Kompakt_XXS_Compact_Line_544x250_cm.pdf",
            },
            {
              name: "Montageanleitung Kompakt XXS Compact Line 544x300 cm",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/6_Aktionsmodell/Montageanleitung_Terrassendach/Montageanleitung_Kompakt_XXS_Compact_Line_544x300_cm.pdf",
            },
            {
              name: "Montageanleitung Kompakt XXS Compact Line 604x250 cm",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/6_Aktionsmodell/Montageanleitung_Terrassendach/Montageanleitung_Kompakt_XXS_Compact_Line_604x250_cm.pdf",
            },
            {
              name: "Montageanleitung Kompakt XXS Compact Line 604x300 cm",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/6_Aktionsmodell/Montageanleitung_Terrassendach/Montageanleitung_Kompakt_XXS_Compact_Line_604x300_cm.pdf",
            },
          ],
        },
      ],
    },
  ],
  [
    ProductGroupKey.EinsteigerXS, ///////
    {
      ProductGroupKey: ProductGroupKey.EinsteigerXS,
      ProductGroupTitle: "Terrassendach",
      Title: "Terrassenüberdachung Einsteiger XS Wandanschluss",
      ShortTitle: "Einsteiger XS Wandanschluss",
      AllowCustomSize: false,
      NoOfPolesFixed: false,
      InstallationHeightEnabled: false,
      ImageSrc: "produkte/wandanschluss/einsteiger-xs/detailseite-einsteiger-xs.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: true,
      GigaGutterAddition: null,
      StandardGutterAddition: 4.5,
      RoofPitchDesc: "Standard 8° (keine andere Dachneigung möglich)",
      RoofsDesc: `16 mm Polycarbonatplatten oder 8 mm VSG-Glas`,
      ColorsDesc: `      <tr>
        <th>Farbe:</th>
        <td>anthrazit glatt (RAL 7019)</td>
      </tr>
      <tr>
        <th></th>
        <td>weiß (RAL 9016)</td>
      </tr>`,
      SketchDimensionsImgUrl: "teasers/masse-breite-tiefe-beispiel.png",
      Downloads: [
        {
          name: "Montageanleitung Terrassendach ",
          children: [
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 300x250cm mit 8,76mm VSG",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_300x250cm_mit_8,76mm_VSG.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 300x250cm Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_300x250cm_Poly.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 300x250cm Poly. mit Verstärkung",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_300x250cm_Poly._mit_Verstaerkung.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 300x300cm mit 8,76mm VSG",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_300x300cm_mit_8,76mm_VSG.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 300x300cm Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_300x300cm_Poly.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 300x300cm Poly. mit Verstärkung",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_300x300cm_Poly._mit_Verstaerkung.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 400x250cm mit 8,76mm VSG",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_400x250cm_mit_8,76mm_VSG.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 400x250cm Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_400x250cm_Poly.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 400x300cm mit 8,76mm VSG",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_400x300cm_mit_8,76mm_VSG.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 400x300cm Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_400x300cm_Poly.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 500x250cm mit 8,76mm VSG",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_500x250cm_mit_8,76mm_VSG.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 500x250cm Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_500x250cm_Poly.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 500x300cm Glas",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_500x300cm_Glas.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 500x300cm Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_500x300cm_Poly.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 600x250cm mit 8,76mm VSG",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_600x250cm_mit_8,76mm_VSG.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 600x250cm Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_600x250cm_Poly.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 600x250cm Poly. mit Verstärkung",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_600x250cm_Poly._mit_Verstaerkung.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 600x300cm mit 8,76mm VSG",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_600x300cm_mit_8,76mm_VSG.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 600x300cm Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_600x300cm_Poly.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 600x300cm Poly. mit Verstärkung",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_600x300cm_Poly._mit_Verstaerkung.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 700x250cm Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_700x250cm_Poly.pdf",
            },
            {
              name: "Montageanleitung Einsteiger XS Wandanschluss Easy Edition 700x300cm Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/1_Einsteiger_XS/Montageanleitung_Terrassendach/Montageanleitung_Einsteiger_XS_Wandanschluss_Easy_Edition_700x300cm_Poly.pdf",
            },
          ],
        },
      ],
    },
  ],
  [
    ProductGroupKey.PremiumXL, ///////
    {
      ProductGroupKey: ProductGroupKey.PremiumXL,
      ProductGroupTitle: "Terrassendach",
      Title: "Terrassenüberdachung Premium XL Wandanschluss",
      ShortTitle: "Premium XL Wandanschluss",
      AllowCustomSize: true,
      NoOfPolesFixed: false,
      DepthMin: 200,
      DepthMax: 500,
      WidthMin: 300,
      WidthMax: 1400,
      InstallationHeightEnabled: true,
      ImageSrc: "standard/standard-detail-teaser.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: true,
      GigaGutterAddition: 5.5,
      StandardGutterAddition: 4,
      RoofPitchDesc: "Standard 8° (flexibel montierbar zwischen 5 bis 15 Grad)",
      RoofsDesc: `16 mm Polycarbonatplatten oder 8 mm VSG-Glas`,
      ColorsDesc: `      <tr>
        <th>Farbe:</th>
        <td>anthrazit struktur (RAL 7019)</td>
      </tr>
      <tr>
        <th></th>
        <td>grau struktur (RAL 9007)</td>
      </tr>
      <tr>
        <th></th>
        <td>weiß (RAL 9016)</td>
      </tr>
      <tr>
        <th></th>
        <td>schwarz struktur (RAL 9005)</td>
      </tr>`,
      SketchDimensionsImgUrl: "/assets/sketches/skizze-premium-xl-standard.svg",
      Downloads: [
        {
          name: "Montageanleitung Terrassendach ",
          children: [
            {
              name: "Montageanleitung Legend Plus Edition und Expert Plus Edition DE",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/2_Premium_XL/Montageanleitung_Terrassendach/Montageanleitung_Legend_Plus_Edition_und_Expert_Plus_Edition_DE.pdf",
            },
          ],
        },
        {
          name: "Montageanleitung Zubehör ",
          children: [
            {
              name: "Montageanleitung 10 mm Modulare Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/2_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_10_mm_Modulare_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung 8 mm Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/2_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_8_mm_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung Aluminiumplanken",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/2_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Aluminiumplanken.pdf",
            },
            {
              name: "Montageanleitung Feste Seitenwand mit Glas",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/2_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Feste_Seitenwand_mit_Glas.pdf",
            },
            {
              name: "Montageanleitung Keilfenster Glas",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/2_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Keilfenster_Glas.pdf",
            },
            {
              name: "Montageanleitung Keilfenster Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/2_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Keilfenster_Poly.pdf",
            },
            {
              name: "Montageanleitung LED Spots",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/2_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_LED_Spots.pdf",
            },
            {
              name: "Montageanleitung Markise T200 einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/2_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Markise_T200_einteilig.pdf",
            },
            {
              name: "Montageanleitung Markise T250 SZ einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/2_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Markise_T250_SZ_einteilig.pdf",
            },
            {
              name: "Markisentuchkollektion NH 2024",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/2_Premium_XL/Montageanleitung_Zubehoer/Markisentuchkollektion_NH_2024.pdf",
            },
            {
              name: "Montageanleitung Schiebetür",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/2_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Schiebetuer.pdf",
            },
            {
              name: "Montageanleitung Seitenwand Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/2_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Seitenwand_Poly.pdf",
            },
          ],
        },
      ],
    },
  ],
  [
    ProductGroupKey.PremiumXLFS, ///////
    {
      ProductGroupKey: ProductGroupKey.PremiumXLFS,
      ProductGroupTitle: "Terrassendach",
      Title: "Terrassenüberdachung Premium XL Freistehend",
      ShortTitle: "Premium XL Freistehend",
      AllowCustomSize: true,
      NoOfPolesFixed: false,
      DepthMin: 200,
      DepthMax: 500,
      WidthMin: 100,
      WidthMax: 1200,
      InstallationHeightEnabled: true,
      ImageSrc: "freestanding/freestanding-detail-teaser.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: true,
      GigaGutterAddition: 16.5,
      StandardGutterAddition: 15,
      RoofPitchDesc: "Standard 8° (flexibel montierbar zwischen 5 bis 15 Grad)",
      RoofsDesc: `16 mm Polycarbonatplatten oder 8 mm VSG-Glas`,
      ColorsDesc: `      <tr>
        <th>Farbe:</th>
        <td>anthrazit struktur (RAL 7019)</td>
      </tr>
      <tr>
        <th></th>
        <td>grau struktur (RAL 9007)</td>
      </tr>
      <tr>
        <th></th>
        <td>weiß (RAL 9016)</td>
      </tr>
      <tr>
        <th></th>
        <td>schwarz struktur (RAL 9005)</td>
      </tr>`,
      SketchDimensionsImgUrl: "/assets/sketches/skizze-premium-xl-standard-freistehend.svg",

      Downloads: [
        {
          name: "Montageanleitung Terrassendach ",
          children: [
            {
              name: "Montageanleitung Freistehend",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/1_Premium_XL/Montageanleitung_Terrassendach/Montageanleitung_Freistehend.pdf",
            },
            {
              name: "Montageanleitung Grundgerüst und Dach",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/1_Premium_XL/Montageanleitung_Terrassendach/Montageanleitung_Grundgeruest_und_Dach.pdf",
            },
          ],
        },
        {
          name: "Montageanleitung Zubehör ",
          children: [
            {
              name: "Montageanleitung 10 mm Modulare Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/1_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_10_mm_Modulare_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung 8 mm Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/1_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_8_mm_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung Aluminiumplanken",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/1_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Aluminiumplanken.pdf",
            },
            {
              name: "Montageanleitung Feste Seitenwand mit Glas",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/1_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Feste_Seitenwand_mit_Glas.pdf",
            },
            {
              name: "Montageanleitung Keilfenster Glas",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/1_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Keilfenster_Glas.pdf",
            },
            {
              name: "Montageanleitung Keilfenster Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/1_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Keilfenster_Poly.pdf",
            },
            {
              name: "Montageanleitung LED Spots",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/1_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_LED_Spots.pdf",
            },
            {
              name: "Montageanleitung Markise T200 einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/1_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Markise_T200_einteilig.pdf",
            },
            {
              name: "Montageanleitung Markise T250 SZ einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/1_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Markise_T250_SZ_einteilig.pdf",
            },
            {
              name: "Markisentuchkollektion NH 2024",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/1_Premium_XL/Montageanleitung_Zubehoer/Markisentuchkollektion_NH_2024.pdf",
            },
            {
              name: "Montageanleitung Schiebetür",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/1_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Schiebetuer.pdf",
            },
            {
              name: "Montageanleitung Seitenwand Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/1_Premium_XL/Montageanleitung_Zubehoer/Montageanleitung_Seitenwand_Poly.pdf",
            },
          ],
        },
      ],
    },
  ],
  [
    ProductGroupKey.SuperiorXXL, ///////
    {
      ProductGroupKey: ProductGroupKey.SuperiorXXL,
      ProductGroupTitle: "Terrassendach",
      Title: "Terrassenüberdachung Superior XXL Wandanschluss",
      ShortTitle: "Superior XXL Wandanschluss",
      AllowCustomSize: true,
      NoOfPolesFixed: true,
      DepthMin: 301,
      DepthMax: 600,
      WidthMin: 251,
      WidthMax: 700,
      InstallationHeightEnabled: false,
      ImageSrc: "superior-roof/superior-roof-detail-teaser.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: false,
      GigaGutterAddition: null,
      StandardGutterAddition: null,
      RoofPitchDesc: "Standard 8° (flexibel montierbar zwischen 5 bis 15 Grad)",
      RoofsDesc: `16 mm Polycarbonatplatten
        <ul>
          <li>klar oder opal</li>
          <li>klar oder opal hitzeabweisend </li>
        </ul>
        8 mm oder 10 mm VSG-Glas (je nach Größe) 
        <ul>
          <li>klar oder mit matter Folie</li>
        </ul>`,
      ColorsDesc: `      <tr>
        <th>Farbe:</th>
        <td>anthrazit struktur (RAL 7019)</td>
      </tr>`,
      SketchDimensionsImgUrl: "/assets/sketches/skizze-superior-xxl.svg",

      Downloads: [
        {
          name: "Montageanleitung Terrassendach ",
          children: [
            {
              name: "Montageanleitung Superior XXL Edition",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/3_Superior_XXL/Montageanleitung_Terrassendach/Montageanleitung_Superior_XXL_Edition.pdf",
            },
          ],
        },
        {
          name: "Montageanleitung Zubehör ",
          children: [
            {
              name: "Montageanleitung 10 mm Modulare Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/3_Superior_XXL/Montageanleitung_Zubehoer/Montageanleitung_10_mm_Modulare_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung 8 mm Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/3_Superior_XXL/Montageanleitung_Zubehoer/Montageanleitung_8_mm_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung Aluminiumplanken",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/3_Superior_XXL/Montageanleitung_Zubehoer/Montageanleitung_Aluminiumplanken.pdf",
            },
            {
              name: "Montageanleitung Feste Seitenwand mit Glas",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/3_Superior_XXL/Montageanleitung_Zubehoer/Montageanleitung_Feste_Seitenwand_mit_Glas.pdf",
            },
            {
              name: "Montageanleitung Keilfenster Glas",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/3_Superior_XXL/Montageanleitung_Zubehoer/Montageanleitung_Keilfenster_Glas.pdf",
            },
            {
              name: "Montageanleitung Keilfenster Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/3_Superior_XXL/Montageanleitung_Zubehoer/Montageanleitung_Keilfenster_Poly.pdf",
            },
            {
              name: "Montageanleitung LED Spots",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/3_Superior_XXL/Montageanleitung_Zubehoer/Montageanleitung_LED_Spots.pdf",
            },
            {
              name: "Montageanleitung Markise T200 einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/3_Superior_XXL/Montageanleitung_Zubehoer/Montageanleitung_Markise_T200_einteilig.pdf",
            },
            {
              name: "Montageanleitung Markise T250 SZ einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/3_Superior_XXL/Montageanleitung_Zubehoer/Montageanleitung_Markise_T250_SZ_einteilig.pdf",
            },
            {
              name: "Markisentuchkollektion NH 2024",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/3_Superior_XXL/Montageanleitung_Zubehoer/Markisentuchkollektion_NH_2024.pdf",
            },
            {
              name: "Montageanleitung Schiebetür",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/3_Superior_XXL/Montageanleitung_Zubehoer/Montageanleitung_Schiebetuer.pdf",
            },
            {
              name: "Montageanleitung Seitenwand Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/3_Superior_XXL/Montageanleitung_Zubehoer/Montageanleitung_Seitenwand_Poly.pdf",
            },
          ],
        },
      ],
    },
  ],
  [
    ProductGroupKey.Schiebedach, ///////
    {
      ProductGroupKey: ProductGroupKey.Schiebedach,
      ProductGroupTitle: "Schiebedach",
      Title: "Terrassenüberdachung Schiebedach",
      ShortTitle: "Schiebedach",
      AllowCustomSize: true,
      NoOfPolesFixed: false,
      DepthMin: 200,
      DepthMax: 400,
      WidthMin: 201,
      WidthMax: 1000,
      InstallationHeightEnabled: false,
      ImageSrc: "sunroof/sunroof-detail-teaser.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: true,
      GigaGutterAddition: 5.5,
      StandardGutterAddition: 4,
      RoofPitchDesc: "Standard 8°",
      RoofsDesc: `16 mm Polycarbonatplatten oder 8 mm VSG-Glas`,
      ColorsDesc: `      <tr>
        <th>Farbe:</th>
        <td>anthrazit struktur (RAL 7019)</td>
      </tr>
      <tr>
        <th></th>
        <td>grau struktur (RAL 9007)</td>
      </tr>
      <tr>
        <th></th>
        <td>weiß (RAL 9016)</td>
      </tr>
      <tr>
        <th></th>
        <td>schwarz struktur (RAL 9005)</td>
      </tr>`,
      SketchDimensionsImgUrl: "/assets/sketches/skizze-premium-xl-schiebedach.svg",

      Downloads: [
        {
          name: "Montageanleitung Terrassendach ",
          children: [
            {
              name: "Montageanleitung Grundgerüst",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/4_Schiebedach/Montageanleitung_Terrassendach/Montageanleitung_Grundgeruest.pdf",
            },
            {
              name: "Schiebedach mit Glas",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/4_Schiebedach/Montageanleitung_Terrassendach/Schiebedach_mit_Glas.PDF",
            },
            {
              name: "Schiebedach mit Polycarbonat",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/4_Schiebedach/Montageanleitung_Terrassendach/Schiebedach_mit_Polycarbonat.PDF",
            },
          ],
        },
        {
          name: "Montageanleitung Zubehör ",
          children: [
            {
              name: "Montageanleitung 10 mm Modulare Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/4_Schiebedach/Montageanleitung_Zubehoer/Montageanleitung_10_mm_Modulare_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung 8 mm Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/4_Schiebedach/Montageanleitung_Zubehoer/Montageanleitung_8_mm_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung Aluminiumplanken",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/4_Schiebedach/Montageanleitung_Zubehoer/Montageanleitung_Aluminiumplanken.pdf",
            },
            {
              name: "Montageanleitung Feste Seitenwand mit Glas",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/4_Schiebedach/Montageanleitung_Zubehoer/Montageanleitung_Feste_Seitenwand_mit_Glas.pdf",
            },
            {
              name: "Montageanleitung Keilfenster Glas",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/4_Schiebedach/Montageanleitung_Zubehoer/Montageanleitung_Keilfenster_Glas.pdf",
            },
            {
              name: "Montageanleitung Keilfenster Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/4_Schiebedach/Montageanleitung_Zubehoer/Montageanleitung_Keilfenster_Poly.pdf",
            },
            {
              name: "Montageanleitung LED Spots",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/4_Schiebedach/Montageanleitung_Zubehoer/Montageanleitung_LED_Spots.pdf",
            },
            {
              name: "Montageanleitung Markise T200 einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/4_Schiebedach/Montageanleitung_Zubehoer/Montageanleitung_Markise_T200_einteilig.pdf",
            },
            {
              name: "Montageanleitung Markise T250 SZ einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/4_Schiebedach/Montageanleitung_Zubehoer/Montageanleitung_Markise_T250_SZ_einteilig.pdf",
            },
            {
              name: "Markisentuchkollektion NH 2024",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/4_Schiebedach/Montageanleitung_Zubehoer/Markisentuchkollektion_NH_2024.pdf",
            },
            {
              name: "Montageanleitung Schiebetür",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/4_Schiebedach/Montageanleitung_Zubehoer/Montageanleitung_Schiebetuer.pdf",
            },
            {
              name: "Montageanleitung Seitenwand Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/4_Schiebedach/Montageanleitung_Zubehoer/Montageanleitung_Seitenwand_Poly.pdf",
            },
          ],
        },
      ],
    },
  ],
  [
    ProductGroupKey.SchiebedachFS, ///////
    {
      ProductGroupKey: ProductGroupKey.SchiebedachFS,
      ProductGroupTitle: "Schiebedach",
      Title: "Terrassenüberdachung Schiebedach Freistehend",
      ShortTitle: "Schiebedach Freistehend",
      AllowCustomSize: true,
      NoOfPolesFixed: false,
      DepthMin: 200,
      DepthMax: 400,
      WidthMin: 201,
      WidthMax: 1000,
      InstallationHeightEnabled: false,
      ImageSrc: "sunroof/sunroof-detail-teaser.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: true,
      GigaGutterAddition: 16.5,
      StandardGutterAddition: 15,
      RoofPitchDesc: "Standard 8°",
      RoofsDesc: `16 mm Polycarbonatplatten oder 8 mm VSG-Glas`,
      ColorsDesc: `      <tr>
        <th>Farbe:</th>
        <td>anthrazit struktur (RAL 7019)</td>
      </tr>
      <tr>
        <th></th>
        <td>grau struktur (RAL 9007)</td>
      </tr>
      <tr>
        <th></th>
        <td>weiß (RAL 9016)</td>
      </tr>
      <tr>
        <th></th>
        <td>schwarz struktur (RAL 9005)</td>
      </tr>`,
      SketchDimensionsImgUrl: "/assets/sketches/skizze-premium-xl-schiebedach-freistehend.svg",

    },
  ],
  [
    ProductGroupKey.FlachdachXLWandanschluss, ///////
    {
      ProductGroupKey: ProductGroupKey.FlachdachXLWandanschluss,
      ProductGroupTitle: "Flachdach",
      Title: "Terrassenüberdachung Flachdach XL Wandanschluss",
      ShortTitle: "Flachdach XL Wandanschluss",
      AllowCustomSize: true,
      NoOfPolesFixed: false,
      DepthMin: 200,
      DepthMax: 400,
      WidthMin: 201,
      WidthMax: 1000,
      InstallationHeightEnabled: false,
      ImageSrc: "produkte/flachdach-xl/wandanschluss/detailseite-banner-flachdach-xl-wandanschluss.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: false,
      GigaGutterAddition: null,
      StandardGutterAddition: null,
      RoofPitchDesc: "Standard 2° (keine andere Neigung möglich)",
      RoofsDesc: `8 mm VSG-Glas oder 16 mm Sandwichplatten weiß`,
      ColorsDesc: `      <tr>
        <th>Farbe:</th>
        <td>anthrazit struktur (RAL 7019)</td>
      </tr>
      <tr>
        <th></th>
        <td>grau struktur (RAL 9007)</td>
      </tr>
      <tr>
        <th></th>
        <td>schwarz struktur (RAL 9005)</td>
      </tr>`,
      SketchDimensionsImgUrl: "/assets/sketches/skizze-flachdach-xl.svg",

      Downloads: [
        {
          name: "Montageanleitung Terrassendach ",
          children: [
            {
              name: "Montageanleitung Flatroof Modell A Flachdach XL ",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Terrassendach/Wandanschluss/Montageanleitung_Flatroof_Modell_A_Flachdach_XL_.pdf",
            },
            {
              name: "Neuer Verbinder",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Terrassendach/Wandanschluss/Neuer_Verbinder.pdf",
            },
          ],
        },
        {
          name: "Montageanleitung Zubehör ",
          children: [
            {
              name: "Montageanleitung 10 mm Modulare Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_10_mm_Modulare_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung 8 mm Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_8_mm_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung Aluminiumplanken",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Aluminiumplanken.pdf",
            },
            {
              name: "Montageanleitung Feste Seitenwand mit Glas",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Feste_Seitenwand_mit_Glas.pdf",
            },
            {
              name: "Montageanleitung LED Spots",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_LED_Spots.pdf",
            },
            {
              name: "Montageanleitung Markise T200 einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Markise_T200_einteilig.pdf",
            },
            {
              name: "Montageanleitung Markise T250 SZ einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Markise_T250_SZ_einteilig.pdf",
            },
            {
              name: "Markisentuchkollektion NH 2024",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Markisentuchkollektion_NH_2024.pdf",
            },
            {
              name: "Montageanleitung Schiebetür",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Schiebetuer.pdf",
            },
            {
              name: "Montageanleitung Seitenwand Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Seitenwand_Poly.pdf",
            },
          ],
        },
      ],
    },
  ],
  [
    ProductGroupKey.FlachdachXLEck, ///////
    {
      ProductGroupKey: ProductGroupKey.FlachdachXLEck,
      ProductGroupTitle: "Flachdach",
      Title: "Terrassenüberdachung Flachdach XL Eck",
      ShortTitle: "Flachdach XL Eck",
      AllowCustomSize: true,
      NoOfPolesFixed: true,
      DepthMin: 200,
      DepthMax: 400,
      WidthMin: 201,
      WidthMax: 1000,
      InstallationHeightEnabled: false,
      ImageSrc: "produkte/flachdach-xl/eck/detailseite-banner-flachdach-xl-eck.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: false,
      GigaGutterAddition: null,
      StandardGutterAddition: null,
      RoofPitchDesc: "Standard 2° (keine andere Neigung möglich)",
      RoofsDesc: `8 mm VSG-Glas oder 16 mm Sandwichplatten weiß`,
      ColorsDesc: `      <tr>
        <th>Farbe:</th>
        <td>anthrazit struktur (RAL 7019)</td>
      </tr>
      <tr>
        <th></th>
        <td>grau struktur (RAL 9007)</td>
      </tr>
      <tr>
        <th></th>
        <td>schwarz struktur (RAL 9005)</td>
      </tr>`,
      SketchDimensionsImgUrl: "/assets/sketches/skizze-flachdach-xl.svg",

      Downloads: [
        {
          name: "Montageanleitung Terrassendach ",
          children: [
            {
              name: "Montageanleitung Flatroof Modell B Flachdach XL ",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Terrassendach/Eck/Montageanleitung_Flatroof_Modell_B_Flachdach_XL_.pdf",
            },
            {
              name: "Neuer Verbinder",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Terrassendach/Eck/Neuer_Verbinder.pdf",
            },
          ],
        },
        {
          name: "Montageanleitung Zubehör ",
          children: [
            {
              name: "Montageanleitung 10 mm Modulare Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_10_mm_Modulare_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung 8 mm Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_8_mm_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung Aluminiumplanken",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Aluminiumplanken.pdf",
            },
            {
              name: "Montageanleitung Feste Seitenwand mit Glas",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Feste_Seitenwand_mit_Glas.pdf",
            },
            {
              name: "Montageanleitung LED Spots",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_LED_Spots.pdf",
            },
            {
              name: "Montageanleitung Markise T200 einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Markise_T200_einteilig.pdf",
            },
            {
              name: "Montageanleitung Markise T250 SZ einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Markise_T250_SZ_einteilig.pdf",
            },
            {
              name: "Markisentuchkollektion NH 2024",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Markisentuchkollektion_NH_2024.pdf",
            },
            {
              name: "Montageanleitung Schiebetür",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Schiebetuer.pdf",
            },
            {
              name: "Montageanleitung Seitenwand Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Seitenwand_Poly.pdf",
            },
          ],
        },
      ],
    },
  ],
  [
    ProductGroupKey.FlachdachXLNische, ///////
    {
      ProductGroupKey: ProductGroupKey.FlachdachXLNische,
      ProductGroupTitle: "Flachdach",
      Title: "Terrassenüberdachung Flachdach XL Nische",
      ShortTitle: "Flachdach XL Nische",
      AllowCustomSize: true,
      NoOfPolesFixed: true,
      DepthMin: 200,
      DepthMax: 400,
      WidthMin: 201,
      WidthMax: 1000,
      InstallationHeightEnabled: false,
      ImageSrc: "produkte/flachdach-xl/nische/detailseite-banner-flachdach-xl-nische.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: false,
      GigaGutterAddition: null,
      StandardGutterAddition: null,
      RoofPitchDesc: "Standard 2° (keine andere Neigung möglich)",
      RoofsDesc: `8 mm VSG-Glas oder 16 mm Sandwichplatten weiß`,
      ColorsDesc: `      <tr>
        <th>Farbe:</th>
        <td>anthrazit struktur (RAL 7019)</td>
      </tr>
      <tr>
        <th></th>
        <td>grau struktur (RAL 9007)</td>
      </tr>
      <tr>
        <th></th>
        <td>schwarz struktur (RAL 9005)</td>
      </tr>`,
      SketchDimensionsImgUrl: "/assets/sketches/skizze-flachdach-xl.svg",

      Downloads: [
        {
          name: "Montageanleitung Terrassendach ",
          children: [
            {
              name: "Montageanleitung Flatroof Modell C Flachdach XL ",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Terrassendach/Nische/Montageanleitung_Flatroof_Modell_C_Flachdach_XL_.pdf",
            },
            {
              name: "Neuer Verbinder",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Terrassendach/Nische/Neuer_Verbinder.pdf",
            },
          ],
        },
        {
          name: "Montageanleitung Zubehör ",
          children: [
            {
              name: "Montageanleitung 10 mm Modulare Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_10_mm_Modulare_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung 8 mm Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_8_mm_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung Aluminiumplanken",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Aluminiumplanken.pdf",
            },
            {
              name: "Montageanleitung Feste Seitenwand mit Glas",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Feste_Seitenwand_mit_Glas.pdf",
            },
            {
              name: "Montageanleitung LED Spots",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_LED_Spots.pdf",
            },
            {
              name: "Montageanleitung Markise T200 einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Markise_T200_einteilig.pdf",
            },
            {
              name: "Montageanleitung Markise T250 SZ einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Markise_T250_SZ_einteilig.pdf",
            },
            {
              name: "Markisentuchkollektion NH 2024",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Markisentuchkollektion_NH_2024.pdf",
            },
            {
              name: "Montageanleitung Schiebetür",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Schiebetuer.pdf",
            },
            {
              name: "Montageanleitung Seitenwand Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/1_Wandanschluss/5_Flachdach/Montageanleitung_Zubehoer/Montageanleitung_Seitenwand_Poly.pdf",
            },
          ],
        },
      ],
    },
  ],
  [
    ProductGroupKey.FlachdachXLFreistehend, ///////
    {
      ProductGroupKey: ProductGroupKey.FlachdachXLFreistehend,
      ProductGroupTitle: "Flachdach",
      Title: "Terrassenüberdachung Flachdach XL Freistehend",
      ShortTitle: "Flachdach XL Freistehend",
      AllowCustomSize: true,
      NoOfPolesFixed: true,
      DepthMin: 200,
      DepthMax: 400,
      WidthMin: 201,
      WidthMax: 1000,
      InstallationHeightEnabled: false,
      ImageSrc: "produkte/flachdach-xl/freistehend/detailseite-banner-flachdach-xl-freistehend.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: true,
      GigaGutterAddition: null,
      StandardGutterAddition: 11,
      RoofPitchDesc: "Standard 2° (keine andere Neigung möglich)",
      RoofsDesc: `8 mm VSG-Glas oder 16 mm Sandwichplatten weiß`,
      ColorsDesc: `      <tr>
        <th>Farbe:</th>
        <td>anthrazit struktur (RAL 7019)</td>
      </tr>
      <tr>
        <th></th>
        <td>grau struktur (RAL 9007)</td>
      </tr>
      <tr>
        <th></th>
        <td>schwarz struktur (RAL 9005)</td>
      </tr>`,
      SketchDimensionsImgUrl: "/assets/sketches/skizze-flachdach-xl-freistehend.svg",

      Downloads: [
        {
          name: "Montageanleitung Terrassendach ",
          children: [
            {
              name: "Montageanleitung Flatroof Modell D Flachdach XL",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/3_Flachdach_XL/Montageanleitung_Terrassendach/Montageanleitung_Flatroof_Modell_D_Flachdach_XL.pdf",
            },
            {
              name: "Neuer Verbinder",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/3_Flachdach_XL/Montageanleitung_Terrassendach/Neuer_Verbinder.pdf",
            },
          ],
        },
        {
          name: "Montageanleitung Zubehör ",
          children: [
            {
              name: "Montageanleitung 10 mm Modulare Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/3_Flachdach_XL/Montageanleitung_Zubehoer/Montageanleitung_10_mm_Modulare_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung 8 mm Glasschiebewand",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/3_Flachdach_XL/Montageanleitung_Zubehoer/Montageanleitung_8_mm_Glasschiebewand.pdf",
            },
            {
              name: "Montageanleitung Aluminiumplanken",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/3_Flachdach_XL/Montageanleitung_Zubehoer/Montageanleitung_Aluminiumplanken.pdf",
            },
            {
              name: "Montageanleitung Feste Seitenwand mit Glas",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/3_Flachdach_XL/Montageanleitung_Zubehoer/Montageanleitung_Feste_Seitenwand_mit_Glas.pdf",
            },
            {
              name: "Montageanleitung LED Spots",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/3_Flachdach_XL/Montageanleitung_Zubehoer/Montageanleitung_LED_Spots.pdf",
            },
            {
              name: "Montageanleitung Markise T200 einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/3_Flachdach_XL/Montageanleitung_Zubehoer/Montageanleitung_Markise_T200_einteilig.pdf",
            },
            {
              name: "Montageanleitung Markise T250 SZ einteilig",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/3_Flachdach_XL/Montageanleitung_Zubehoer/Montageanleitung_Markise_T250_SZ_einteilig.pdf",
            },
            {
              name: "Markisentuchkollektion NH 2024",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/3_Flachdach_XL/Montageanleitung_Zubehoer/Markisentuchkollektion_NH_2024.pdf",
            },
            {
              name: "Montageanleitung Schiebetür",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/3_Flachdach_XL/Montageanleitung_Zubehoer/Montageanleitung_Schiebetuer.pdf",
            },
            {
              name: "Montageanleitung Seitenwand Poly",
              url: "https://www.mein-terrassendach24.de/downloads/montageanleitungen/2_Freistehend/3_Flachdach_XL/Montageanleitung_Zubehoer/Montageanleitung_Seitenwand_Poly.pdf",
            },
          ],
        },
      ],
    },
  ],
  [
    ProductGroupKey.FlachdachXXL,
    {
      ProductGroupKey: ProductGroupKey.FlachdachXXL,
      ProductGroupTitle: "Flachdach",
      Title: "Terrassenüberdachung Flachdach XXL",
      ShortTitle: "Flachdach XXL",
      AllowCustomSize: true,
      NoOfPolesFixed: false,
      DepthMin: 251,
      DepthMax: 400,
      WidthMin: 301,
      WidthMax: 700,
      InstallationHeightEnabled: false,
      ImageSrc: "produkte/flachdach-xxl/detail-teaser.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: false,
      GigaGutterAddition: null,
      StandardGutterAddition: null,
      RoofPitchDesc: "Standard 2° (keine andere Neigung möglich)",
      RoofsDesc: `8 mm VSG-Glas oder 16 mm Sandwichplatten weiß`,
      ColorsDesc: `      <tr>
        <th>Farbe:</th>
        <td>anthrazit struktur (RAL 7019)</td>
      </tr>
      <tr>
        <th></th>
        <td>grau struktur (RAL 9007)</td>
      </tr>
      <tr>
        <th></th>
        <td>schwarz struktur (RAL 9005)</td>
      </tr>`,

      SketchDimensionsImgUrl: "/assets/sketches/skizze-flachdach-xl.svg",

    },
  ],

  [
    ProductGroupKey.Lamellendach, ///////
    {
      ProductGroupKey: ProductGroupKey.Lamellendach,
      ProductGroupTitle: "Lamellendach",
      Title: "Terrassenüberdachung Lamellendach",
      ShortTitle: "Lamellendach",
      AllowCustomSize: false,
      NoOfPolesFixed: false,
      DepthMin: 100,
      DepthMax: 400,
      WidthMin: 100,
      WidthMax: 700,
      InstallationHeightEnabled: true,
      ImageSrc: "garden-room/garden-room-detail-teaser.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: true,
      GigaGutterAddition: null,
      StandardGutterAddition: 4,
      RoofPitchDesc: "Standard 8° (flexibel montierbar zwischen 5 bis 15 Grad)",
      RoofsDesc: `16 mm Polycarbonatplatten oder 8 mm VSG-Glas`,
      ColorsDesc: `      <tr>
                <th>Farbe:</th>
                <td>anthrazit struktur (RAL 7019)</td>
              </tr>
              <tr>
                <th></th>
                <td>grau struktur (RAL 9007)</td>
              </tr>
              <tr>
                <th></th>
                <td>weiß (RAL 9016)</td>
              </tr>
              <tr>
                <th></th>
                <td>schwarz struktur (RAL 9005)</td>
              </tr>`,
    },
  ],
  [
    ProductGroupKey.Gartenzimmer, ///////
    {
      ProductGroupKey: ProductGroupKey.Gartenzimmer,
      ProductGroupTitle: "Gartenzimmer",
      Title: "Terrassenüberdachung Gartenzimmer",
      ShortTitle: "Gartenzimmer",
      AllowCustomSize: false,
      NoOfPolesFixed: false,
      DepthMin: 100,
      DepthMax: 400,
      WidthMin: 100,
      WidthMax: 700,
      InstallationHeightEnabled: true,
      ImageSrc: "garden-room/garden-room-detail-teaser.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: true,
      GigaGutterAddition: null,
      StandardGutterAddition: 4,
      RoofPitchDesc: "Standard 8° (flexibel montierbar zwischen 5 bis 15 Grad)",
      RoofsDesc: `16 mm Polycarbonatplatten oder 8 mm VSG-Glas`,
      ColorsDesc: `      <tr>
                <th>Farbe:</th>
                <td>anthrazit struktur (RAL 7019)</td>
              </tr>
              <tr>
                <th></th>
                <td>grau struktur (RAL 9007)</td>
              </tr>
              <tr>
                <th></th>
                <td>weiß (RAL 9016)</td>
              </tr>
              <tr>
                <th></th>
                <td>schwarz struktur (RAL 9005)</td>
              </tr>`,
    },
  ],
  [
    ProductGroupKey.Lagerverkauf,
    {
      ProductGroupKey: ProductGroupKey.Lagerverkauf,
      ProductGroupTitle: "Terrassendach",
      Title: "Terrassenüberdachung Lagerverkauf",
      ShortTitle: "Terrassenüberdachung Lagerverkauf",
      AllowCustomSize: false,
      NoOfPolesFixed: true,
      DepthMin: 100,
      DepthMax: 400,
      WidthMin: 100,
      WidthMax: 1000,
      InstallationHeightEnabled: false,
      ImageSrc: "garden-room/garden-room-detail-teaser.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: true,
      GigaGutterAddition: null,
      StandardGutterAddition: 4.5,
      RoofPitchDesc: "Standard 8° (flexibel montierbar zwischen 5 bis 15 Grad)",
      RoofsDesc: `16 mm Polycarbonatplatten oder 8 mm VSG-Glas`,
      ColorsDesc: `      <tr>
        <th>Farbe:</th>
        <td>anthrazit glatt (RAL 7019)</td>
      </tr>
      <tr>
        <th></th>
        <td>weiß (RAL 9016)</td>
      </tr>`,
    },
  ],
  [
    ProductGroupKey.Gartenhaus,
    {
      ProductGroupKey: ProductGroupKey.Gartenhaus,
      ProductGroupTitle: "Gartenhaus",
      Title: "Gartenhaus Standard",
      ShortTitle: "Gartenhaus Standard",
      AllowCustomSize: false,
      NoOfPolesFixed: null,
      DepthMin: 100,
      DepthMax: 400,
      WidthMin: 100,
      WidthMax: 1000,
      InstallationHeightEnabled: false,
      ImageSrc: "garden-room/garden-room-detail-teaser.jpg",
      ImageDesc: "Beispieldarstellung",
      ShowGutter: true,
      GigaGutterAddition: null,
      StandardGutterAddition: 4.5,
      RoofPitchDesc: "Standard 8° (flexibel montierbar zwischen 5 bis 15 Grad)",
      RoofsDesc: `Dachbeschreibung`,
      ColorsDesc: "Farbbeschreibung",
    },
  ],
]);
