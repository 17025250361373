<h1 class="p-3 mtd24-bg-header" [ngClass]="headerStyleSize">
  <ng-content></ng-content>
  @if (promotions) {
    @for (p of promotions; track p.Id) {
      <span class="discount-text ms-0 ms-md-2 p-1"
        ><span class="text-uppercase"> {{ p.Title }}</span> gültig bis zum {{ p.ValidUntil | date }}</span
      >
    }
  }
</h1>
